import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ThemeProvider, StyledEngineProvider, Typography } from '@mui/material';
import { orderBy, size } from 'lodash';
import request from 'superagent';
import Slider from 'react-slick';
import { muiTheme, defaultThemeOption } from './utils/tools';
import EventsCard from './components/EventsCard';
import NewsCard from './components/NewsCard';
import Loader from './utils/Loader';
import config from './config';

/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role,jsx-a11y/no-noninteractive-element-interactions */
function LeftNavButton(props) {
  const { className, onClick } = props;
  return (
    <img
      src={
        "https://def773hwqc19t.cloudfront.net/website/static/left-arrow.png"
      }
      onClick={onClick}
      onKeyDown={onClick}
      className={className}
      style={{ width: 45, height: 45, left: -50 }}
      alt={"Précédent"}
    />
  );
}

function RightNavButton(props) {
  const { className, clickNext } = props;
  return (
    <img
      src={
        "https://def773hwqc19t.cloudfront.net/website/static/right-arrow.png"
      }
      onClick={clickNext}
      onKeyDown={clickNext}
      className={className}
      style={{ width: 45, height: 45, right: -50 }}
      alt={"Suivant"}
    />
  );
}


class Diaporama extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { cityId, aggloId, cityINSEE, aggloSIREN, topicId, actorId, excludeActors, type } = this.props;
    if (type === 'actualites' && (cityId || aggloId)) {
      console.log('diapo actualite');
      this.fetchNews(cityId, aggloId, topicId, actorId, excludeActors);
    } else if (cityId || aggloId) {
      this.fetchEvents(cityId, aggloId);
    } else if (cityINSEE || aggloSIREN) {
      this.fetchEventsByCode(cityINSEE, aggloSIREN);
    }
  }

  goNext = () => {
    if (this.slider) {
      this.slider.slickNext();
    }
  };

  fetchNews(cityId, aggloId, topicId, actorId, excludeActors) {
    request
      .get(config.apiRoot + config.apiNews)
      .query({ 'city-id': cityId })
      .query({ 'agglo-id': aggloId })
      .query({ 'topic-id': topicId })
      .query({ 'actors-id': actorId })
      .query({ 'exclude-actors': excludeActors })
      .query({ 'source': 'widget' })
      .then(res => res.body)
      .then(items => {
        this.setState({
          items: orderBy(items, ['published_at'], ['desc']),
          fetched: true,
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  fetchEvents(cityId, aggloId) {
    request
      .get(config.apiRoot + config.apiEvents)
      .query({ 'city-id': cityId })
      .query({ 'agglo-id': aggloId })
      .query({ 'source': 'widget' })
      .then(res => res.body)
      .then(items => {
        this.setState({
          items: orderBy(items, ['start_date'], ['asc']),
          fetched: true,
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  fetchEventsByCode(cityINSEE, aggloSIREN) {
    request
      .get(config.apiRoot + config.apiEvents)
      .query({ 'city-insee': cityINSEE })
      .query({ 'agglo-siren': aggloSIREN })
      .query({ 'source': 'widget' })
      .then(res => res.body)
      .then(items => {
        this.setState({
          items: orderBy(items, ['start_date'], ['asc']),
          fetched: true,
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  render() {
    const { items, error, fetched } = this.state;
    const { type } = this.props;
    if (!items) {
      if (error || fetched) {
        return null;
      }
      return <Loader />;
    }

    const customTypography = window.forceFont
      ? { fontFamily: ['Jost', 'sans-serif'].join(',') }
      : {};

    const themeOptions = {
      ...defaultThemeOption,
      typography: customTypography,
    };

    const theme = muiTheme(themeOptions);
    console.log(theme);

    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
      autoplay: window.speed && window.speed > 0,
      autoplaySpeed: window.speed ? window.speed * 1000 : 5000,
      pauseOnHover: false,
      nextArrow: <RightNavButton clickNext={() => this.goNext()} />,
      prevArrow: <LeftNavButton />,
      // fade: true,
    };

    if (size(items) < 1) {
      return (
        <div
          style={{
            marginRight: '3em',
            marginLeft: '3em',
            marginTop: '3em',
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <Typography
                align="center"
                style={{
                  fontWeight: 'bold',
                  marginTop: 20,
                  marginBottom: 20,
                  color: '#333',
                }}
                variant="h4"
              >
                {type === 'actualites'
                  ? 'Aucun élément à afficher'
                  : 'Aucun événement programmé'}
              </Typography>
            </ThemeProvider>
          </StyledEngineProvider>
        </div>
      );
    }

    return (
      <div
        style={{
          marginRight: '3em',
          marginLeft: '3em',
          marginTop: '3em',
        }}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Slider
              ref={(slider) => {
                this.slider = slider
                return this.slider
              }}
              {...settings}>
              {items.map(item => {
                if (type === 'actualites') {
                  return <NewsCard news={item} key={item.id} diaporama />;
                }
                return <EventsCard event={item} key={item.id} diaporama />;
              })}
            </Slider>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
  }
}

Diaporama.propTypes = {
  cityId: PropTypes.number,
  aggloId: PropTypes.number,
  cityINSEE: PropTypes.string,
  aggloSIREN: PropTypes.string,
  type: PropTypes.string,
};

export default Diaporama;
