import React from 'react';
import ReactDOM from 'react-dom';
import {
  StylesProvider,
  createGenerateClassName,
} from '@mui/styles';
import Diaporama from './Diaporama.jsx';
import './intramuros.css';
import commonPaths from '../webpack/paths';

const generateClassName = createGenerateClassName({
  seed: 'diapo',
});

export function initDiaporamaWidget(
  cityId,
  aggloId,
  cityINSEE,
  aggloSIREN,
  topicId,
  actorId,
  excludeActors,
  type,
) {
  if (document.getElementById('intramuros_diaporama')) {
    console.log('Init IntraMuros Diaporama widget');
    ReactDOM.render(
      <StylesProvider generateClassName={generateClassName}>
        <Diaporama
          cityId={cityId}
          aggloId={aggloId}
          cityINSEE={cityINSEE}
          aggloSIREN={aggloSIREN}
          topicId={topicId}
          actorId={actorId}
          excludeActors={excludeActors}
          type={type}
        />
      </StylesProvider>,
      document.getElementById('intramuros_diaporama'),
    );
  } else {
    console.log('No tag with id: intramuros_diaporama');
  }
}

// Launch widget
console.log(`Widget Diaporama v${commonPaths?.widgetVersion} chargé.`);
console.log('TARGET_ENV: ' + process.env.TARGET_ENV);
console.log('NODE_ENV: ' + process.env.NODE_ENV);
const {
  intramurosCityId,
  intramurosAggloId,
  intramurosCityINSEE,
  intramurosAggloSIREN,
  intramurosTopicId,
  intramurosActorId,
  intramurosExcludeActors,
  type,
} = window;

console.log('City ID = ' + intramurosCityId);
console.log('Agglo ID = ' + intramurosAggloId);
console.log('Topic ID = ' + intramurosTopicId);
console.log('Actor ID = ' + intramurosActorId);
console.log('Exclude Actors = ' + intramurosExcludeActors);

if (
  intramurosCityId ||
  intramurosAggloId ||
  intramurosCityINSEE ||
  intramurosAggloSIREN
) {
  initDiaporamaWidget(
    intramurosCityId,
    intramurosAggloId,
    intramurosCityINSEE,
    intramurosAggloSIREN,
    intramurosTopicId,
    intramurosActorId,
    intramurosExcludeActors,
    type,
  );
}
